.mt-16{
    margin-top: 16px;
}

.mt-24{
    margin-top: 24px;
}

.mt-50{
    margin-top: 50px;
}

.mt-80{
    margin-top: 80px;
}


.mr-2{
    margin-right: 8px !important;
}

.mr-8{
    margin-right: 8px; 
}

.mr-16{
    margin-right: 16px; 
}

.mr-24{
    margin-right: 24px; 
}

.ml-4{
    margin-left: 4px; 
}

.ml-8{
    margin-left: 8px; 
}

.mb-16{
    margin-bottom: 16px;
}

.mb-24{
    margin-bottom: 24px;
}

.mb-48{
    margin-bottom: 48px;
}

.mb-64{
    margin-bottom: 64px;
}



.flex-column{
    display: flex; 
    flex-direction: column;
}

.flex-start{
    justify-content: flex-start;
}

.align-start{
    align-items: flex-start;
}

.flex{
    display: flex;
}


.flex-1{
    flex: 1; 
    /* border: 1px solid red;  */
}

.text-center{
    text-align: center;
}

.flex{
    display: flex;
}


.mini-width{
    width: 90%;
    max-width: 800px;
    margin: auto;
    scale: 0.0005; 
    opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: entry -50px;
}

.mini-width.visible {
    scale: 1; 
    opacity: 1; 
}

@keyframes fade-in {
    to {
        scale: 1; 
        opacity: 1;
    }
}

.min-width{
    width: 800px;
    margin: auto;
}

h1 {
    font-size: 55px !important;
  }
  

@media only screen and (max-width: 850px) {
    .mini-width{
        width: 90%;
    }
} 
  
@media only screen and (max-width: 600px) {
    .mt-16 {
        margin-top: 8px;
    }
    .mt-24 {
        margin-top: 12px;
    }
    .mt-50 {
        margin-top: 25px;
    }
    .mt-80 {
        margin-top: 40px;
    }

    .mr-2 {
        margin-right: 4px !important;
    }
    .mr-8 {
        margin-right: 4px;
    }
    .mr-16 {
        margin-right: 8px;
    }
    .mr-24 {
        margin-right: 12px;
    }

    .ml-4 {
        margin-left: 2px;
    }
    .ml-8 {
        margin-left: 4px;
    }

    .mb-16 {
        margin-bottom: 8px;
    }
    .mb-24 {
        margin-bottom: 12px;
    }
    .mb-48 {
        margin-bottom: 24px;
    }
    .mb-64 {
        margin-bottom: 32px;
    }

    .mini-width {
        width: 100%;
        margin: auto;
        scale: 0.0005;
        opacity: 0;
        animation: fade-in linear forwards;
        animation-timeline: view();
        animation-range: entry -50px;
    }

    .min-width {
        width: 100%;
        margin: auto;
    }

    h1 {
        font-size: 35px !important;
    }

    /* Ensure images and containers are responsive */
    img, .container {
        max-width: 100%;
        height: auto;
    }

    /* Adjust button sizes for better tap targets */
    .btn {
        padding: 12px 20px;
        font-size: 16px;
    }

    /* Adjust text sizes for readability */
    .text-center {
        font-size: 14px;
    }

    /* Ensure flex containers stack vertically */
    .flex {
        flex-direction: column;
    }

    /* Adjust padding and margins for better spacing */
    .padding-16 {
        padding: 8px;
    }
    .margin-16 {
        margin: 8px;
    }
}
