body{
  background-color: black;
  padding:0 10px;
}

@font-face {
	font-family: "quicksand";
	src: url("fonts/Quicksand-VariableFont_wght.ttf") format("truetype-variations");
	font-weight: 100 1000;
}

*{
  margin: 0px; 
  box-sizing: border-box;
  font-family: "quicksand";
  /* color: white;  */
}

.header-1{
  font-size: 50px; 
  font-weight: 500;
}

.header-2{
  font-size: 30px; 
}

.header-3{
  font-size: 20px; 
}

.norm-text{
  font-size: 18px; 
}

.color-white{
  color: white; 
}

.landing-nav{
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0px;
  position: sticky;
  height: 100px;
  /* border: 5px solid white;  */
  width: 95%; 
  margin: auto; 
}

.logo{
  width: 100px;
}

.btn{
  padding: 16px 24px; 
  border-radius: 8px;
  border: 2px solid white; 
  color: white; 
  height: fit-content;
  cursor: pointer; 
  transition: 0.2s;
  text-decoration: none;
  text-align: center;
}

.btn:hover,
.btn:focus-visible{
  transform: scale(1.03);
}

.store-btn{
  display: flex; 
  align-items: center;
  padding: 10px 16px; 
  text-decoration: none;
  opacity: 0;
  transform: translateY(20px);
  filter: blur(5px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, filter 0.5s ease-out;
}

.store-btn.visible {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}
.store-icon{
  font-size: 28px; 
}

.mini{
  font-size: 12px; 
  margin: 0px; 
}

.store-bold{
  font-size: 22px;
  margin: 0px;
}

.landing-hero{
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.landing-hero-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflow */
  border-right: 2px solid; /* Cursor effect */
  animation: text-appear 0.8s ease-out forwards, blur-in 0.5s ease-out forwards; /* New animations */
}

.landing-hero-text div,
.landing-hero-text h1,
.landing-hero-text p {
  opacity: 0;
  transform: translateY(20px);
  filter: blur(5px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, filter 0.5s ease-out;
}

.landing-hero-text div.visible,
.landing-hero-text h1.visible,
.landing-hero-text p.visible {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}

@keyframes text-appear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blur-in {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}

.hero-image{
  width: 200px; 
}

.landing-text{
  width: 100%;
  position: relative; 
  font-size: 23px !important;
  /* max-width: 300px; */
  white-space: initial;
}

.landing-text-header {
  color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative; 
  font-size: 45px !important;
}

/*intro section*/
.landing-intro{
  /* border: 1px solid white;  */
  width: 1250px; 
  height: 200px; 
  margin: 120px auto 0px auto;
  border-radius: 8px;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  color: white; 
  padding: 24px;
  position: relative;
  border-top: none; 
}

.landing-intro-icons-h{
  position: absolute;
  top: -15px; 
  left: 50%; 
  transform: translateX(-50%);
  font-size: 35px;
  z-index: 10;
  width: 180px; 
  display: flex;
  justify-content: space-between;
}

/*about section*/
.landing-join{
  width: 100%; 
  background-color: #ccc;
  margin-top: 100px; 
  padding: 30px 0px;
}

.landing-j-inner{
  display: flex; 
  justify-content: space-between;
  width: 1250px; 
  /* height: 500px; */
  margin: auto;
  
}

.landing-j-1{
  flex: 1; 
  /* height: 100%;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-j-2{
  flex: 1; 
  text-align: center;
  font-size: 400px;
}

.store-image{
  height: 50px; 
  cursor: pointer; 
}

.join-image{
  height: 100%; 
}

.rotate{
  transform: rotate(20deg);
}

/*read more section*/
.landing-read-more{
  width: 1250px; 
  margin: 80px auto 0px auto;
  display: flex;
  color: white; 
  align-items: center;
}

.read-title{
  width: 300px;
  margin-right: 16px;
}

.read-down-c{
  color: white; 
  font-size: 200px; 
  margin-bottom: 16px;
}

.landing-pointers{
  width: 1250px;
  margin: auto;
  color: white; 
}


.horizontal-line{
  width: 100%; 
  border-top: 3px solid white; 
  margin: auto;
}


.app-pointer-h{
  display: flex; 

}

.app-pointer{
  flex: 1; 
  text-align: center;
}

.app-pointer:not(:last-child){
  margin-right: 24px;
}

.mini-line{
  border-top: 3px solid white; 
  margin: auto;
  width: 20%;
}

.app-icon{
  font-size: 60px;
  margin-bottom: 16px;
}

/*steps section*/
.landing-steps{
  background-color: #25252c;
  width: 1250px; 
  /* height: 300px;  */
  margin: auto;
  margin-top: 100px;
  border-radius: 16px;
  color: white; 
  padding: 24px; 
}

.landing-steps-t{
  border-bottom: 1px solid rgba(255,255,255,.07);
  padding-bottom: .75em;
}

.steps-lower-h{
  display: flex; 
}

.steps{
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
}

.steps:not(:last-child){
  margin-right: 32px;
}

.step-index{
  background-color: white;
  width: 50px; 
  height: 50px; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 50%;
  margin-bottom: 16px;
}

.landing-footer{
  width: 1250px; 
  margin: 50px auto 24px auto; 
  display: flex;
  justify-content: space-between;
  color: white;
}

.footer-socials{
  display: flex; 
}

.social{
  width: 25px;
  height: 25px; 
  background-color: white;
  color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.social:not(:last-child){
  margin-right: 16px;
  cursor: pointer;
}

/*how to use section*/
.how-to-use{
  width: fit-content;
  margin: auto;
}

.how-to-use-inner{
  display: flex;
  justify-content: center;
}

.how-to-use-header{
  color: white;
  font-size: 28px;
  text-align: center;
}

.phone-image{
  height: 450px;
}

.phone-image:not(:last-child){
  margin-right: 12px;
}

/*side section*/
.side-holder{
  width: 100%; 
  margin: auto; 
  height: 500px; 
  margin-top: 30px;
  display: flex; 
  color: white;
}

.side-holder:last-child{
  margin-bottom: 30px;
}

.side-first{
  flex: 1; 
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reversed{
  flex-direction: row-reverse;
}

.flex-start{
  justify-content: flex-start;
}

.flex-end{
  justify-content: flex-end !important;  
}

.align-end{
  align-items: flex-end;
}

.align-start{
  align-items: flex-start;
}

.side-h2{
  font-size: 35px;
  color: white; 
}

.side-p {
  font-size: 24px;
}

.side-h4 {
  font-size: 35px;
}

.side-second{
  flex: 1; 
  height: 100%; 
  display:flex; 
  align-items: center;
  justify-content: center;
}

.side-image{
  height: 500px;
}

ul {
  list-style-type: none;
}

ul, li{
  line-height: 35px;
}

.homePageImage {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding-right: 20px;
  scale: 0.0005; opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry;
}

.homePageImage.visible {
  scale: 1; 
  opacity: 1; 
}

@keyframes fade-in {

  to {scale: 1; opacity: 1;}

}

.picture {
  width: 100%;
  align-self: center;
}

.picture-girl {
  width: 100%;
  align-self: center;
  /* padding: 0px 20px; */
}

.picture-screen {
  width: 100%;
  align-self: center;
  padding: 0px 20px;
}

@media only screen and (max-width: 450px) {
  .store-holder{
    flex-direction: column;
  }

  .store-btn{
    width: 100%; 
    align-items: center;
  }

  .store-btn:first-child{
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 850px) {
  .side-image{
    height: 400px;
  }
} 

@media only screen and (max-width: 600px) {
  .side-holder{
    flex-direction: column;
    margin-bottom: 0px !important;
    height: fit-content;
  }

  .side-second{
    align-items: center !important;
    justify-content: center !important;
  }

  .side-first{
    margin-bottom: 24px;
  }
}


@media only screen and (max-width: 600px) {
  .homePageImage {
    width: 130%;
    margin-top: 0px !important;
    margin-bottom: 0px;
    border-radius: 5px;
    padding-right: 20px;
    scale: .8; opacity: 0;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: entry;
  }

  @keyframes fade-in {
    to {scale: 1; opacity: 1;}
  }

  .picture {
    width: 100%;
    align-self: center;
  }
  
  .picture-girl {
    width: 130%;
    align-self: center;
    padding: 0px 20px;
  }
  
  .picture-screen {
    width: 130%;
    align-self: center;
    padding: 0px 20px;
  }

  .side-holder {
    margin-top: 20px;
  }
}

/* Adjust font sizes for smaller screens */
@media only screen and (max-width: 1200px) {
  .header-1 {
    font-size: 40px;
  }
  .header-2 {
    font-size: 25px;
  }
  .header-3 {
    font-size: 18px;
  }
  .norm-text {
    font-size: 16px;
  }
  .landing-text-header {
    font-size: 35px !important;
  }
}

/* Adjust layout for medium screens */
@media only screen and (max-width: 850px) {
  .landing-j-inner {
    flex-direction: column;
    align-items: center;
  }
  .landing-j-1, .landing-j-2 {
    width: 100%;
    text-align: center;
  }
  .landing-steps {
    width: 100%;
  }
  .landing-footer {
    flex-direction: column;
    align-items: center;
  }
  .footer-socials {
    margin-top: 16px;
  }
}

/* Adjust layout for small screens */
@media only screen and (max-width: 600px) {
  .landing-nav {
    flex-direction: column;
    align-items: flex-end;
    height: auto;
  }
  .btn {
    text-align: center;
    margin-bottom: 16px;
  }
  .landing-hero {
    margin-top: 20px;
  }
  .landing-intro {
    width: 100%;
    height: auto;
    margin: 60px auto 0px auto;
    padding: 16px;
  }
  .landing-join {
    padding: 20px 0px;
  }
  .landing-read-more {
    flex-direction: column;
    align-items: flex-start;
  }
  .read-title {
    width: 100%;
    margin-bottom: 16px;
  }
  .landing-pointers {
    width: 100%;
  }
  .landing-steps {
    padding: 16px;
  }
  .landing-footer {
    width: 100%;
  }
  .side-holder {
    flex-direction: column;
    height: auto;
  }
  .side-image {
    height: 300px;
  }
}